import * as React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import ModulesSlides from "../components/modules/ModulesSlides"
import { useEffect } from "react"
import { subscribe, unsubscribe } from "pubsub-js"
import { useState } from "react"

export const query = graphql`
  query ProjectQ($slug: String!) {
    sanityProject(slug: { current: { eq: $slug } }) {
      id
      slug {
        current
      }
      seo {
        metaTitle
        metaDescription
        metaImage {
          asset {
            url
          }
        }
      }
      title
      category {
        title
      }
      slides {
        modules {
          ... on SanityImageUI {
            _type
            _key
            title
            layout
            layoutArea
            gridArea
            size
            align
            image: _rawImage(resolveReferences: { maxDepth: 10 })
            caption: _rawCaption(resolveReferences: { maxDepth: 10 })
          }
          ... on SanityTexteUI {
            _type
            _key
            title
            layout
            layoutArea
            gridArea
            texte: _rawTexte(resolveReferences: { maxDepth: 10 })
          }
          ... on SanityVideoUI {
            _key
            _type
            layout
            layoutArea
            gridArea
            url
            aspectRatio
            showControls
            size
            caption: _rawCaption(resolveReferences: { maxDepth: 10 })
          }
        }
      }
    }
  }
`

const Project = ({ data }) => {
  const { slug, seo, title, category, slides } = data.sanityProject
  console.log(title)
  const [amount, setAmount] = useState(0)
  useEffect(() => {
    const token = subscribe("SLIDE_AMOUNT", (e, d) => {
      // console.log(d)
      setAmount(d)
    })
    return () => unsubscribe(token)
  }, [])

  return (
    <div className="page">
      {seo && (
        <Seo
          metaTitle={seo.metaTitle}
          metaDescription={seo.metaDescription}
          metaImage={seo.metaImage?.asset.url}
          template={`template-project project-${slug.current} layout-slides`}
          page={true}
        />
      )}
      <div className="header top-header-height fixed left-0 z-10 flex">
        <div className="category cartouche-box">
          <div className="label">{category?.title}</div>
        </div>
        <h1 className="cartouche-box b-t">
          <div className="label ellipsis">{title}</div>
        </h1>
        <div
          className="amount h-1 absolute bottom-0 left-0 bg-accent transition-all z-10 mix-blend-luminosity"
          style={{
            width: `${amount}%`,
          }}
        ></div>
      </div>
      <ModulesSlides input={slides} />
    </div>
  )
}

export default Project
